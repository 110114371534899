export const FastlyHeaders = {
  CountryCode: 'Fastly-Geo-Country',
  Latitude: 'Fastly-Geo-Latitude',
  Longitude: 'Fastly-Geo-Longitude',
  PostalCode: 'Fastly-Geo-Postal-Code',
  GMTOffset: 'Fastly-Geo-GMT-Offset',
  UTCOffset: 'Fastly-Geo-UTC-Offset',
  ProxyType: 'Fastly-Geo-Proxy-Type',
} as const;

export const AuthHeaders = {
  IsAnonymous: 'X-IHR-Is-Anonymous',
} as const;

export const AcceptEncoding = 'Accept-Encoding';
export const UserTypeHeader = 'X-User-Type';
export const NormalizedThemeHeader = 'X-Theme';
export const MarketHeader = 'X-Market';
export const UserABTestGroupHeader = 'X-User-AB-Test-Group';

/** This header is supported by some browsers out of the box */
export const SecCHPrefersColorScheme = 'Sec-Ch-prefers-color-scheme';
/** This header is a custom header implemented in userland to add support for browsers/environments which don't natively support `Sec-CH-Prefers-Color-Scheme */
export const CHPrefersColorScheme = 'CH-prefers-color-scheme';

/** This is the cookie key to track the user-selected theme. It's possible that this cookie is nullish. */
export const iHeartRadioTheme = 'iheartradio-theme';

export const VaryHeaders = [
  AcceptEncoding,
  MarketHeader,
  NormalizedThemeHeader,
  SecCHPrefersColorScheme,
  UserTypeHeader,
  UserABTestGroupHeader,
] as const;
