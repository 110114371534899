import {
  LoginIcon,
  Navigation,
  SettingsIcon,
} from '@iheartradio/web.companion';
import type { User } from '@iheartradio/web.config';
import { NavLink } from '@remix-run/react';
import { $path } from 'remix-routes';

export function LoginSettingsNavItem({
  user,
  onClick,
}: {
  user: User | null;
  onClick: () => void;
}) {
  return !user || user?.isAnonymous ?
      <Navigation.Item
        as={NavLink}
        data-test="login"
        onClick={onClick}
        to={$path('/login/:action?', { action: 'auth' })}
      >
        <LoginIcon />
        Log In
      </Navigation.Item>
    : <Navigation.Item
        as={NavLink}
        data-test="settings"
        target="_blank"
        to={$path('/settings')}
      >
        <SettingsIcon />
        Settings
      </Navigation.Item>;
}
