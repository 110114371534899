import { lightDark } from '@iheartradio/web.accomplice';
import { Box } from '@iheartradio/web.accomplice/box';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Separator } from '@iheartradio/web.accomplice/separator';
import { Spacer } from '@iheartradio/web.accomplice/spacer';
import { Text } from '@iheartradio/web.accomplice/text';
import {
  AdChoicesIcon,
  FacebookIcon,
  InstagramIcon,
  PrivacyChoicesIcon,
  TikTokIcon,
  XIcon,
  YoutubeIcon,
} from '@iheartradio/web.companion';

import { FooterBackToIheart } from './footer-back-to-iheart';
import { FooterLink } from './footer-link';
import { FooterSocial } from './footer-social';

export const Footer = () => {
  const copyrightText = (
    <Text
      css={{ paddingRight: '$32', color: lightDark('$gray400', '$gray300') }}
      kind="caption-4"
    >
      © {new Date().getFullYear()} iHeartMedia, Inc.
    </Text>
  );

  return (
    <Box
      alignSelf="flex-end"
      asChild
      data-test="footer"
      direction="column"
      display="flex"
      width="100%"
    >
      <footer>
        <Spacer
          bottom="$32"
          top="$40"
          x={{
            mobile: '$16',
            large: '$24',
            xlarge: '$32',
          }}
        >
          <FooterBackToIheart />
          <Separator backgroundColor={lightDark('$gray200', '$gray500')} />
          <Flex
            flexDirection={{
              mobile: 'column',
              large: 'row',
            }}
            flexWrap="wrap"
            left="$2"
            right="$16"
          >
            <Box asChild display="flex" paddingRight="$32" paddingTop="$16">
              <ul>
                <FooterSocial
                  icon={FacebookIcon}
                  link="https://www.facebook.com/iheartradio"
                  name="facebook"
                />
                <FooterSocial
                  icon={XIcon}
                  link="https://x.com/iHeartRadio"
                  name="x"
                />
                <FooterSocial
                  icon={InstagramIcon}
                  link="https://www.instagram.com/iHeartRadio/"
                  name="instagram"
                />
                <FooterSocial
                  icon={TikTokIcon}
                  link="https://www.tiktok.com/@iheartradio?lang=en"
                  name="tiktok"
                />
                <FooterSocial
                  icon={YoutubeIcon}
                  link="https://www.youtube.com/user/iHeartRadio"
                  name="youtube"
                />
              </ul>
            </Box>
            <Flex flexWrap="wrap">
              <Flex
                alignItems="center"
                display={{
                  mobile: 'none',
                  medium: 'flex',
                  xlarge: 'inline-flex',
                }}
                paddingTop="$16"
              >
                {copyrightText}
              </Flex>
              <Box
                alignItems="center"
                asChild
                columnGap="$24"
                display="flex"
                flexWrap="wrap"
                paddingTop="$16"
                rowGap="$8"
              >
                <ul>
                  <FooterLink link="https://www.iheart.com/help">
                    Help
                  </FooterLink>
                  <FooterLink link="https://www.iheart.com/privacy">
                    Privacy Policy
                  </FooterLink>
                  <FooterLink link="https://www.iheart.com/terms">
                    Terms of Use
                  </FooterLink>
                  <FooterLink link="https://privacy.iheart.com/">
                    <Flex alignItems="center" gap="$4">
                      Your Privacy Choices
                      <PrivacyChoicesIcon size="16" />
                    </Flex>
                  </FooterLink>
                  <FooterLink link="https://www.iheart.com/adchoices">
                    <Flex alignItems="center" gap="$4">
                      AdChoices
                      <AdChoicesIcon
                        fill={{ light: 'gray-450', dark: 'gray-250' }}
                        size="18"
                      />
                    </Flex>
                  </FooterLink>
                </ul>
              </Box>
            </Flex>
            <Flex
              alignItems="center"
              display={{ medium: 'none' }}
              paddingTop="$16"
            >
              {copyrightText}
            </Flex>
          </Flex>
        </Spacer>
      </footer>
    </Box>
  );
};
