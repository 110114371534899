import { Button } from '@iheartradio/web.accomplice/button';
import { Flex } from '@iheartradio/web.accomplice/flex';
import { Link } from '@iheartradio/web.accomplice/link';
import { Popover, PopoverText } from '@iheartradio/web.accomplice/popover';
import { Separator } from '@iheartradio/web.accomplice/separator';
import { Spacer } from '@iheartradio/web.accomplice/spacer';
import {
  BrowseIcon,
  HomeIcon,
  LogotypeBeta,
  media,
  Navigation,
  useMediaQuery,
  YourLibraryIcon,
} from '@iheartradio/web.companion';
import { NavLink, useLocation } from '@remix-run/react';
import { memo, useCallback, useRef } from 'react';
import { $path } from 'remix-routes';
import { ClientOnly } from 'remix-utils/client-only';

import { NavAd } from '~app/ads/display/nav-ad';
import { trackClick } from '~app/analytics/track-click';
import { useRegGateEvent } from '~app/analytics/use-reg-gate';
import { useUser } from '~app/contexts/user';
import type { AppsFlyerOffset } from '~app/hooks/apps-flyer/use-apps-flyer';
import { useDismissiblePopover } from '~app/hooks/use-dismissible-popover';
import { useGetPageName } from '~app/hooks/use-get-page-name';
import { REG_GATE_TRIGGER_TYPES, Routes } from '~app/utilities/constants';

import { LoginSettingsNavItem } from './login-settings-item';

interface NavProps {
  appsFlyerOffset: AppsFlyerOffset;
}

function usePopoverTrigger({
  onPress,
  profileId,
  useSpacer,
}: {
  onPress: () => void;
  profileId?: number;
  useSpacer?: boolean;
}) {
  return useSpacer ?
      <Spacer left="$24" top="$16">
        <Button
          css={{ background: '$gradientBrightBlue' }}
          data-test="share-feedback-button"
          href={`https://surveys.segmanta.com/927u43?userId=${profileId}`}
          id="user-education-feedback"
          inline
          kind="primary"
          onPress={onPress}
          rel="noreferrer"
          size="large"
          target="_blank"
        >
          Share feedback
        </Button>
      </Spacer>
    : <Button
        css={{ background: '$gradientBrightBlue' }}
        data-test="share-feedback-button"
        href={`https://surveys.segmanta.com/927u43?userId=${profileId}`}
        id="user-education-feedback"
        inline
        kind="primary"
        onPress={onPress}
        rel="noreferrer"
        size="small"
        target="_blank"
      >
        Share feedback
      </Button>;
}

const FeedbackPopover = memo(function FeedbackPopover({
  onPress,
  profileId,
  useSpacer = false,
}: {
  onPress: () => void;
  profileId?: number;
  useSpacer?: boolean;
}) {
  const popoverRef = useRef<HTMLElement | null>(null);
  const [isOpen, setOpen] = useDismissiblePopover({
    closeAfterMs: 10_000,
    popoverRef,
  });

  const Trigger = usePopoverTrigger({
    onPress,
    profileId,
    useSpacer,
  });

  const otherPopoverProps: Record<string, unknown> = {};

  return (
    <ClientOnly>
      {() => {
        return (
          <Popover
            fixed
            isNonModal
            isOpen={isOpen}
            name="feedback"
            offset={16}
            onOpenChange={setOpen}
            placement="bottom"
            ref={popoverRef}
            trigger={Trigger}
            {...otherPopoverProps}
          >
            <PopoverText>
              Your feedback is essential for us to improve our site.
            </PopoverText>
          </Popover>
        );
      }}
    </ClientOnly>
  );
});

export function Nav({ appsFlyerOffset }: NavProps) {
  const user = useUser();
  const { pathname } = useLocation();
  const pageName = useGetPageName();
  const { onAnalyticsRegGateOpen } = useRegGateEvent();

  const isLarge = useMediaQuery(media.large);

  const popoverOnClick = useCallback(() => {
    trackClick(pageName, 'navigation|share-feedback');
  }, [pageName]);

  return (
    <>
      <Navigation
        css={{ zIndex: '$2', '@large': { paddingBottom: '9.1rem !important' } }}
        data-has-button
        data-test="navigation-wrapper"
        key={`${user?.isAnonymous ? 'anon' : 'auth'}-nav`}
      >
        <Navigation.Header css={{ marginTop: appsFlyerOffset }}>
          <Link asChild>
            <NavLink to={$path('/')}>
              <LogotypeBeta
                aria-label="iHeart Header Logotype"
                media="mobile"
                size={24}
              />
            </NavLink>
          </Link>
          {!isLarge ?
            <FeedbackPopover
              onPress={popoverOnClick}
              profileId={user?.profileId}
            />
          : null}
        </Navigation.Header>
        <Flex
          flexDirection="column"
          height="100%"
          justifyContent="flex-start"
          width="100%"
        >
          <Navigation.Logo>
            <Link asChild>
              <NavLink to={$path('/')}>
                <LogotypeBeta
                  aria-label="iHeart Navigation Logotype"
                  media="desktop"
                  size={24}
                />
              </NavLink>
            </Link>
          </Navigation.Logo>
          <Flex direction="column" height="100%" justifyContent="space-between">
            <Flex
              direction="column"
              id="user-education"
              justifyContent="flex-start"
            >
              <Navigation.List>
                <Navigation.Item
                  active={pathname === $path('/')}
                  as={NavLink}
                  data-test="home"
                  to={$path('/')}
                >
                  <HomeIcon />
                  Home
                </Navigation.Item>
                <Navigation.Item
                  active={pathname.startsWith(Routes.Browse.Root)}
                  as={NavLink}
                  data-test="browse"
                  id="user-education-browse"
                  to={$path('/browse/live')}
                >
                  <BrowseIcon />
                  Browse
                </Navigation.Item>
                <Navigation.Item
                  active={pathname.startsWith('/library')}
                  as={NavLink}
                  data-test="library"
                  id="user-education-library"
                  to={$path('/library/live')}
                >
                  <YourLibraryIcon />
                  Library
                </Navigation.Item>
                <Separator
                  display={{ mobile: 'none', large: 'inline-block' }}
                />
                <LoginSettingsNavItem
                  onClick={() =>
                    onAnalyticsRegGateOpen(REG_GATE_TRIGGER_TYPES.LOG_IN)
                  }
                  user={user}
                />
              </Navigation.List>
              {isLarge ?
                <FeedbackPopover
                  onPress={popoverOnClick}
                  profileId={user?.profileId}
                  useSpacer
                />
              : null}
            </Flex>
            <Spacer bottom="$24" left="$8">
              <NavAd />
            </Spacer>
          </Flex>
        </Flex>
      </Navigation>
    </>
  );
}
