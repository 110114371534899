import { Link } from '@iheartradio/web.accomplice/link';
import { Text } from '@iheartradio/web.accomplice/text';
import { NavLink } from '@remix-run/react';
import type { ReactNode } from 'react';

type FooterLinkProps = {
  link: string;
  target?: string;
  children?: ReactNode;
};

export const FooterLink = ({ link, children }: FooterLinkProps) => {
  return (
    <Text as="li" css={{ listStyle: 'none' }} kind="caption-4">
      <Link asChild color="secondary" underline="hover">
        <NavLink target="_blank" to={link}>
          {children}
        </NavLink>
      </Link>
    </Text>
  );
};
